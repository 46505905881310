import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Icon from 'src/components/UI/MarkdownIcon'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import { SectionWrapper } from './style'
import ProdutosJSON from './data.json'
import usePageQuery from './../../pageQuery'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

const { qrCodeAberturaDeContaRendaVariavel } = qrcodeInvestimentsData

type produtoItem = {
  title: string;
  description: string;
  icon?: string;
  image?: string;
}

interface IProdutosCompletosProps {
  handleClick: () => void;
  sendDatalayerEvent: Function;
}

const ProdutosCompletos = ({ handleClick, sendDatalayerEvent }: IProdutosCompletosProps) => {
  const width = useWidth()
  const data = usePageQuery()

  return (
    <SectionWrapper id='produtos-completos'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-12 col-lg-3'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 text-center text-lg-left'>
              Produtos <span className='d-inline d-lg-block'>completos</span> para você
            </h2>
            { width >= 992 && (
              <span
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none button-investir'
                onClick={() => {
                  handleClick()
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Produtos completos para você',
                    element_action: 'click button',
                    element_name: 'Quero Investir',
                  })
                }}
              >Quero Investir
              </span>
              )}
          </div>
          <div className='col-12 col-lg-8'>
            <DefaultCarousel
              sm={{
                  items: 1,
                  partialVisibilityGutter: 40,
              }}
              md={{
                  items: 2,
              }}
              lg={{
                  items: 2,
              }}
              xl={{
                  items: 2,
              }}
            >
              {ProdutosJSON.map((item: produtoItem, index: number) => (
                <div key={index}>
                  <div className='border rounded-5 p-4 mx-3 carousel-card'>
                    {item.icon ? <Icon color='orange--extra' width='30' height='30' icon={item.icon} directory='v2' /> : ''}
                    {item.image ? <Img fluid={data[item.image].fluid} /> : ''}
                    <h3 className='fs-16 lh-20 text-grayscale--500 mt-5 mb-2' dangerouslySetInnerHTML={{ __html: item.title }} />
                    <p className='fs-16 lh-20 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                </div>
              ))}
            </DefaultCarousel>
            { width < 992 &&
              (
                <div className='text-center'>
                  <a
                    href={qrCodeAberturaDeContaRendaVariavel.deeplink}
                    className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none button-investir mt-4'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        section_name: 'Produtos completos para você',
                        element_action: 'click button',
                        element_name: 'Quero Investir',
                      })
                    }}
                  >
                    Quero Investir
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ProdutosCompletos
